import { React } from 'react';
import { Popup as ReactMapGLPopup } from 'react-map-gl';

import {
  Box,
  Flex,
  Heading,
  Image,
  ExternalLink,
  Text,
} from 'shared/components/primitives';

import { eventTime, longDate } from 'shared/utils/date';

import constructionSiteMarkerSVG from 'shared/assets/images/icon-marker-orange.svg';
import tbmIcon from 'shared/assets/images/tbm.svg';
import ReactHtmlParser from 'react-html-parser';

export const TbmPopup = ({ siteName, detail, ...props }) => {
  return (
    <Popup
      icon={tbmIcon}
      iconWidth="15%"
      content={siteName}
      detail={
        <div align="center">
          {ReactHtmlParser(detail)}
        </div>
      }
      stripeColor="light-gray-background"
      {...props}
    />
  );
};

export const ConstructionSitePopup = ({ siteName, ...props }) => {
  return (
    <Popup
      icon={constructionSiteMarkerSVG}
      content={siteName}
      stripeColor="primary-orange-background"
      {...props}
    />
  );
};

export const TrafficAlertPopup = ({ alert, detail, ...props }) => {
  if (props.desc === undefined && alert === null) return null;
  const isTbm = props.desc.includes('<p>');

  return (
    <Popup
      ref={props.ref}
      alert={alert}
      icon={props.icon}
      iconWidth="10%"
      stripeColor={props.color}
      closeOnClick={true}
      detail={
        <div align="center">
          {props.desc}
        </div>
      }
      {...props}
    />
  );
};

export const Popup = ({
  showPopup,
  toggleShowPopup,
  children,
  latitude,
  longitude,
  stripeColor,
  image,
  icon,
  iconWidth,
  content,
  hideButton,
  link,
  detail,
  alert,
  nullAlert,
  ...props
}) => {
  if (!latitude || !longitude) return null; // return if no latitude or longitude are passed

  return showPopup && (
    <ReactMapGLPopup
      latitude={latitude}
      longitude={longitude}
      closeButton={false}
      closeOnClick={true}
      onClose={() => { toggleShowPopup(false); nullAlert(); }}
      tipSize={20}
      anchor="bottom"
      offsetTop={-40}
      hideButton={hideButton ? true : false}

    >
      <Flex
        minWidth="14rem"
        maxWidth="18rem"
        flexDirection="column"
        bg="dark-background"
        color="white"
      >
        <Flex
          as="header"
          flexDirection="column"
          width="full"
          height={image ? 'auto' : '2rem'}
          backgroundColor={image ? null : stripeColor}
        >
          {image && (
            <Image
              src={image.url ? image.url : null}
              alt={image.alt ? image.alt : null}
            />
          )}
          {icon && <Image position="absolute" width={'15%' ? '15%' : null} src={icon} top=".75rem" />}
        </Flex>
        <Flex flexDirection="column" px="1.125rem" py="1.5rem">
          <>
            <Box width="full">
              {alert && alert.alertId === 'tbm' && <Heading as="h4" fontWeight="bold" pb='0.5rem' fontSize="1.125rem" mb=".5rem">
                {props?.desc ? props.desc : alert ? alert.message : ''}
              </Heading>}

              {content && props.color !== 'secondary-blue-background' && (
                <Box mb={['.5rem']}>
                  <Text fontWeight="bold" pb="0.5rem">
                    {content}
                  </Text>
                </Box>
              )}
              {detail && (
                <Text mt="1rem">
                  {ReactHtmlParser(detail.props.children)}
                </Text>
              )}
              {alert && alert.alertId !== 'tbm' && props.color !== 'light-gray-background' && (
                <>
                  {(alert.startDate?.length > 0 || alert.endDate?.length > 0) && <Box mb={['.5rem']} mt="1rem">
                    <Heading as="h5">Dates:</Heading>
                    <Text
                      display="block"
                      color="event-card-long-date-light-color"
                      fontSize={['xs', null, 'sm']}
                      fontWeight="semibold"
                    >
                      {longDate(alert.startDate)}
                      {alert.endDate && ` – ${longDate(alert.endDate)}`}
                    </Text>
                  </Box>}
                  <Box>
                    {(alert.startDate?.length > 0 || alert.endDate?.length > 0) &&
                    <>
                    <Heading as="h5">When:</Heading>
                    <Text
                      display="block"
                      color="event-card-long-date-light-color"
                      fontSize={['xs', null, 'sm']}
                      fontWeight="semibold"
                    >
                      {eventTime(alert.startDate)}
                      {alert.endDate && ` – ${eventTime(alert.endDate)}`}
                    </Text></>}
                    {alert.link && (
                      <Box mt={['1rem']}>
                        <ExternalLink variant="pill" href={alert.link}>
                          View More
                        </ExternalLink>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </>
        </Flex>
      </Flex>
    </ReactMapGLPopup>
  )
};
