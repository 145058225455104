import { React, useEffect, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Flex,
  Grid,
  Heading,
  Section,
  Text,
  IFrame,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import DocumentList from 'shared/components/documents/DocumentList';
import View from 'community/View';
import EventList from 'community/events/EventList';
import {
  DocumentProvider,
  useDocuments,
  usePage,
} from 'shared/contexts';

import { useCheckDevice } from 'shared/hooks/hooks';
import { Link } from 'react-router-dom';

function IntroSection() {
  const [{ contentItems }] = usePage();

  return (
    <Section bg="green-background">
      <Container>
        <Flex
          flexDirection={['column', null, null, 'row']}
          color="white"
        >
          <Box
            flexShrink={[null, null, null, 0]}
            flexBasis={['auto', null, null, '40%']}
            width={['full']}
            pb={['1rem', null, null, 0]}
          >
            <Heading as="h2" variant="section" color="white" mb={[0]}>
              {contentItems && ReactHtmlParser(contentItems[0]?.text)}
            </Heading>
          </Box>
          <Box pl={[null, null, null, '2rem']}>
            <Text lineHeight={[1.35]}>
              {contentItems && ReactHtmlParser(contentItems[1]?.html)}
            </Text>
          </Box>
        </Flex>
      </Container>
    </Section>
  );
}

function GroupDocuments() {
  const [page] = usePage();
  const [documents, loading, error] = useDocuments(); // get documents

  const [sagDocsGroup1, setsagDocsGroup1] = useState([]);
  const [sagDocsGroup2, setsagDocsGroup2] = useState([]);
  const [sagDocsGroup3, setsagDocsGroup3] = useState([]);
  const [sagDocsGroup4, setsagDocsGroup4] = useState([]);
  const [sagDocsGroup5, setsagDocsGroup5] = useState([]);
  const isMobile = useCheckDevice();

  useEffect(() => {

    setsagDocsGroup1(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2024-2025 Stakeholder Advisory Group'
        );
      })
    );

    setsagDocsGroup2(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2023-2024 Stakeholder Advisory Group'
        );
      })
    );

    setsagDocsGroup3(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2022-2023 Stakeholder Advisory Group'
        );
      })
    );

    setsagDocsGroup4(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2021-2022 Stakeholder Advisory Group'
        );
      })
    );

    // set local state to filtered list of documents with the 'Stakeholder Advisory Group' category

    setsagDocsGroup5(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2019 Stakeholder Advisory Group'
        );
      })
    );

    // set local state to filtered list of documents with the 'Stakeholder Advisory Group' category
  }, [documents]);

  const videoUrl = page && page.videoUrl ? page?.videoUrl : null;
  const videoCaption =
    page && page.contentItems ? page?.contentItems[2]?.text : null;

  return (
    <Box
      width="full"
      flex="1"
      pt={['3rem', null, null, 0]}
      flexGrow={[1, null, null, 0]}
      flexShrink="0"
      flexBasis={['auto', null, null, '100%']}
    >
      <Box mb={['1.5rem']}>
        <Heading as="h3" variant="sidebar" mb={['2rem']}>
          2024-2025 Stakeholder Advisory Group Meeting Materials
        </Heading>
        {sagDocsGroup1.length > 0 ?
          <DocumentList data={sagDocsGroup1} loading={loading} error={error} />
          : "No Meeting Materials Available"
        }
      </Box>
      <Box mb={['2.5rem']}>
        {videoUrl && !isMobile && (
          <Flex mt={['2.5rem']} flexDirection="column" alignItems="stretch">
            <Box height={['15rem']}>
              <IFrame
                src={videoUrl.startsWith('https://') ? videoUrl : `https://`}
              />
            </Box>
            <Box mt={['1rem']}>
              <Text as="p" color="scarpa-flow-text-color">
                {ReactHtmlParser(videoCaption)}
              </Text>
            </Box>
          </Flex>
        )}
      </Box>
      <Box>
        <Heading as="h3" variant="sidebar" mb={['2rem']}>
          Past Meeting Materials
        </Heading>
        <Grid
          flow='row'
          rows='auto'
          columns='1fr'
          gap='1rem'
          boxShadow='unset'
        >
          <DocumentList data={sagDocsGroup2} loading={loading} error={error} />
          <DocumentList data={sagDocsGroup3} loading={loading} error={error} />
          <DocumentList data={sagDocsGroup4} loading={loading} error={error} />
          <DocumentList data={sagDocsGroup5} loading={loading} error={error} />
        </Grid>
      </Box>
    </Box>
  );
}

function GroupInfoSection() {
  return (
    <Section>
      <Container>
        <Flex
          flexDirection={['column', 'column', null, 'row']}
          alignItems={['flex-start']}
        >
          <DocumentProvider>
            <GroupDocuments />
          </DocumentProvider>
        </Flex>
      </Container>
    </Section>
  );
}

function GroupMessageSection() {
  return (
    <Section>
      <Container>
        <Flex
          flexDirection='column'
          alignItems={['flex-start']}
        >
          <Heading fontSize='1.5rem' mb='1rem'>Over the course of six years, AlexRenew worked closely with members of its RiverRenew Stakeholder Advisory Groups, who represented the Alexandria Community.</Heading>
          <Box mb='1rem'>These groups oversaw important phases of the RiverRenew program, including planning, design, and construction, providing critical feedback that helped shape the project and ensure we met needs of our community.</Box>
          <Box mb='1rem'><Text fontWeight='bold'>The Planning SAG (2017, 2018)</Text> recommended alignment options for the tunnel system, challenged the team to broaden the scope of RiverRenew, and supported transfer of the program from City to AlexRenew.</Box>
           
          <Box mb='1rem'><Text fontWeight='bold'>The Design SAG (2019, 2020)</Text> provided input on AlexRenew's first rate increase, gave feedback on final tunnel route and facility locations, reviewed the development of Request for Proposal documents, and promoted Community Listening Sessions.</Box>
           
          <Box><Text fontWeight='bold'>The Construction SAG (2021-2024)</Text> welcomed Hazel the TBM, promoted and attended tunnel tours, supported AlexRenew's Lifeline Emergency Assistance Program, and oversaw numerous construction milestones.</Box>
      </Flex>
      </Container>
    </Section>
  );
}

const StakeholderAdvisoryGroup = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <View>
      <IntroSection />
      <GroupMessageSection />
      <GroupInfoSection />
    </View>
  );
};

export default StakeholderAdvisoryGroup;
