import styled from 'styled-components';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

export const StyledCard = styled('div')(
  (props) =>
    css({
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: 'white',
      borderRadius: 6,
      transitionProperty: 'background-color',
      transitionDuration: '250ms',
      transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.320, 1)',
    }),
  composedStyledUtilities
);

export const StyledEventCard = styled(StyledCard)({
  lineHeight: '22px',
  transform: 'scale(1)',
  transition: 'transform 250ms cubic-bezier(0.23, 1, 0.320, 1)',
  '&:hover': {
    transform: 'scale(1.01)',
  },
});

export const StyledMemberCard = styled(StyledCard)((props) =>
  css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: props?.width ? props.width : 'full',
    minHeight: props?.minHeight ? props.minHeight : '21rem',
    px: ['1rem', '1.5rem', null, null, '2rem'],
    py: ['2rem'],
    backgroundColor: 'normal-gray-background',
    boxShadow: 'none',
  })
);

export const StyledSiteCard = styled(StyledCard)({
  borderRadius: '4px',
  backgroundColor: 'black',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  '&::after': {
    content: JSON.stringify(''),
    position: 'absolute',
    opacity: 0.3,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 0,
    borderRadius: '4px',
    backgroundColor: 'black',
  },
});

export const StyledFeatureCard = styled(StyledCard)(
  ({ backgroundImage, height }) =>
    css({
      width: 'full',
      backgroundImage: [
        `url(${backgroundImage?.sm})`,
        `url(${backgroundImage?.md})`,
        `url(${backgroundImage?.lg})`,
        `url(${backgroundImage?.xl})`,
        `url(${backgroundImage?.xxl})`,
      ],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: height || '17.5rem',
    })
);

export const StyledVideoCard = styled(StyledCard)((props) =>
  css({
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: props?.backgroundColor ? props.backgroundColor : 'white',
    boxShadow: props?.boxShadow
      ? props.boxShadow
      : '0 3px 6px 0 rgba(0, 0, 0, .16)',
    borderRadius: 6,
    alignItems: 'center',
    maxWidth: props.maxWidth ? props.maxWidth : null,
  })
);

export const StyledArticleCard = styled(StyledCard)((props) =>
  css({
    display: 'flex',
    flex: 1,
    flexDirection: props?.flexDirection ? props.flexDirection : 'column',
    borderRadius: props?.borderRadius ? props.borderRadius : '.25rem',
    '&:hover': {
      backgroundColor: 'green-background',
      '*': {
        color: 'white',
      },
    },
  })
);
